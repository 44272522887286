import scrollToElement from '../../services/Scroll'

document.addEventListener('nette:valid', event => {
    if (event.defaultPrevented) {
        return
    }

    const element = event.target

    if (element.tagName.toLowerCase() != 'button') {
        const errorElement = element.parentNode.querySelector('.InputError')
        if (errorElement) {
            element.parentNode.removeChild(errorElement)
        }
    }

    element.classList.remove('has-error')
    element.classList.add('is-valid')
})

document.addEventListener('nette:invalid', event => {
    if (event.defaultPrevented) {
        return
    }

    const element = event.target

    let errorElement = element.parentNode.querySelector('.InputError')

    if (!errorElement) {
        errorElement = document.createElement('div')
        errorElement.className = 'InputError'
    }

    errorElement.innerHTML = element.getAttribute('data-nette-error')
    element.parentNode.appendChild(errorElement)
    element.classList.add('has-error')
    element.classList.remove('is-valid')
})

document.addEventListener('nette:form:valid', event => console.info('Form valid', event.target))
document.addEventListener('nette:form:invalid', event => {
    const form = event.target
    const error = form.querySelector('.InputError')
    const target = error ? error.closest('.FormItem') : null
    scrollToElement(target ? target : form, -120)
})