// import ScrollService from './NativeScroll'
import ScrollService from './VirtualScroll'

ScrollService.getScrollOffset = () => {
    const fixedHeader = document.querySelector('.FixedHeader')
    if (!fixedHeader) {
        return 100
    }
    return fixedHeader.offsetHeight + fixedHeader.offsetTop + 10
}

export const disableScroll = () => ScrollService.disableScroll()
export const enableScroll = () => ScrollService.enableScroll()
export const getScroll = () => ScrollService.getScroll()
export const scrollToElement = (element, options) => ScrollService.scrollToElement(element, options)

export default ScrollService